import HTTPService from '@/api/http/HTTPService'
import SiteInterface from './SiteInterface'
import SiteSettingsInterface from './SiteSettingsInterface'

export default {
  getAll: () => HTTPService.get('site'),
  create: (payload: SiteInterface) => HTTPService.post('site', payload),
  getOne: (siteSlug: string) => HTTPService.get(`site/${siteSlug}`),
  update: (siteSlug: string, payload: SiteInterface) => HTTPService.put(`site/${siteSlug}`, payload),
  delete: (siteSlug: string) => HTTPService.delete(`site/${siteSlug}`),
  deleteTg: (siteSlug: string, payload: string) => HTTPService.delete(`site/${siteSlug}/telegram-recipient`, { telegram_ids: payload }),
  updateSettings: (siteSlug: string, payload: SiteSettingsInterface) => HTTPService.put(`site/${siteSlug}/settings`, payload),
  updateWorkCounter: (siteSlug: string, payload: string) => HTTPService.put(`site/${siteSlug}/completed-works-count`, payload),
  updateSearchLinks: (siteSlug: string, payload: string) => HTTPService.put(`site/${siteSlug}/search-links`, payload),
  updateWebhooks: (siteSlug: string, payload: string) => HTTPService.put(`site/${siteSlug}/webhooks`, payload),
  activate: (siteSlug: string) => HTTPService.post(`site/${siteSlug}/activate`),
  generate: (siteSlug: string) => HTTPService.post(`site/${siteSlug}/generate`),
  import: (siteSlug: string) => HTTPService.post(`site/${siteSlug}/import`),
  generateFavicon: (siteSlug: string, payload: string[]) => HTTPService.put(`site/${siteSlug}/favicon`, payload),
}
